import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
 static targets = ["input", "preview", "button"]

  openFilePicker() {
    this.inputTarget.click();
  }

  previewImage() {
    const input = this.inputTarget;
    const file = input.files[0];
    if (file) {
      this.buttonTarget.classList.add("d-none")
      this.previewTarget.classList.remove("d-none")
      const reader = new FileReader();
      reader.onload = () => {
        this.previewTarget.src = reader.result;
      };
      reader.readAsDataURL(file);
    }
    else {
      this.buttonTarget.classList.remove("d-none")
      this.previewTarget.classList.add("d-none")
    }
  }
}